import React from 'react'
import './style.scss'
import { useStaticQuery, graphql } from "gatsby"

function SocialLinks(props) {

  const { sanitySiteSettings: settings } = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        facebook
        instagram
      }
    }
  `)

  const data = {
    icons: [
      settings?.facebook ?
        {
          icon: 'facebook',
          link: settings.facebook
        }
        :
        null,
      settings?.instagram ?
        {
          icon: 'instagram',
          link: settings.instagram
        }
        :
        null,
      settings?.youtube ?
        {
          icon: 'youtube',
          link: settings.youtube
        }
        :
        null
    ].filter(item => item)
  }


  const icons = data?.icons?.map((node, i) => {

    let icon = node.icon === 'facebook' ? 'FaceBook' :
      node.icon === 'twitter' ? 'Twitter' :
        node.icon === 'youtube' ? 'YouTube' :
          node.icon === 'instagram' ? 'Instagram' :
            node.icon === 'git' ? 'Git' :
              null
    return i === 0 ? (
      <div key={i} className={`social-link`}>
        <a href={node.link} target='_blank' rel='noopener noreferrer'>
          {icon}
        </a>
      </div>
    ) : (
      <div key={i} className={`social-link`}> |
        <a href={node.link} target='_blank' rel='noopener noreferrer'>
          {icon}
        </a>
      </div>
    )
  })


  return (
    <div className={`social-links ${props.classes}`}>
      {icons}
    </div>
  )
}

export default SocialLinks